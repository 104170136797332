import api from '@/libs/axios'
import axios from 'axios'
const axiosPrivate = axios.create({
	baseURL: `${process.env.VUE_APP_API_URL}/api/private`,
	timeout: 18000,
	headers: {
		Authorization: "secret"
	},
  })
export default {
	async getList(params) {
		let response = await api.get('/admin/earns/cashflows/early-claim', { params })
		return response
	},
	async getAll(params){
		let response = await api.get('/admin/earns/cashflows/early-claim-all', { params })
		return response
	},
	async getAllMissionDaily(params){
		let response = await api.get('/admin/daily-missions', { params })
		return response
	},
	async getAllMissionEvent(params){
		let response = await api.get('/admin/event-missions', { params })
		return response
	},
	async getAllMissionNewbie(params){
		let response = await api.get('/admin/newbie-missions', { params })
		return response
	},
	async getUserProfile(payload){
		let response = await axiosPrivate.post('/users/users-info', payload)
		return response
	}
}